<template>
    <div>
        <v-chip
          v-if="reservation.advance_payment_paid || (reservation.calculator && reservation.calculator.advance_payment && reservation.calculator.advance_payment.paid)"
          color="green"
          class="white--text"
          small
        >
            {{ $t('Paid') }}
            <v-icon small right dark color="white">mdi-check</v-icon>
        </v-chip>
        <!-- <span v-else>{{ $t('Rok za uplatu:') }} {{ fullDateFormat(reservation.advance_payment_due_date, true) }}</span> -->
        <div v-else>
          <!-- <v-icon>
            mdi-calendar-clock
          </v-icon> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"

              >
                mdi-clock-outline
              </v-icon>
            </template>
            <span>{{$t('Upfront payment deadline')}}</span>
          </v-tooltip>
          <span>
            {{ fullDateFormat(reservation.advance_payment_due_date, true) }}
          </span>
        </div>
    </div>
</template>
<script>
import { fullDateFormat } from '@/components/calendar/config';

export default {
  props: {
    reservation: {
      required: false,
      type: Object,
    },
  },
  methods: {
    fullDateFormat,
  },
};
</script>
