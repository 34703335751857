<template>
    <div>
        <v-row class="px-3">
          <v-col cols="12" class="px-0 pt-2">
              <v-row>
                <v-col cols="12" md="4" class="pb-0 pr-md-1 mb-4">
                  <v-card outlined class="px-3 py-2">
                    <yes-no-component
                      v-model="form.advance_payment"
                      label="Avans"
                      @input="form.advance_payment = $event"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="4" class="px-md-2">
                  <v-card outlined class="px-3 py-2">
                    <yes-no-component
                      v-model="form.paid"
                      label="Reservation paid"
                      @input="form.paid = $event"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="4" class="pl-md-1">
                  <v-card outlined class="px-3 py-2" max-height="116">
                    <yes-no-component
                      v-model="form.custom_pricing"
                      label="Custom Price"
                      @input="customPricingChanged"
                    />
                    <v-text-field
                      v-if="form.custom_pricing"
                      v-model="form.custom_price.total"
                      :error-messages="firstError('form.custom_price.total')"
                      label="Total"
                      dense
                      append-icon="€"
                      outlined
                      @input="debounceCustomPriceTotalChanged"
                      class="mt-4"
                    />
                    <modal
                      :show="showCustomPriceRemoveConfirm"
                      @close="closeCustomPriceRemoveConfirmModal"
                      @save="removeCustomPrice"
                      save-text="Confirm"
                      :title="'Remove custom price'"
                      :description="'Are you sure you want to remove custom price? If you confirm, your avans values will reset if avans is not paid.'"
                      hide-top-close-button
                      max-width="400"
                    />
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-if="form.advance_payment" class="mt-4 mt-md-0">
                <v-col>
                  <v-card outlined class="px-3 py-3">
                    <v-row>
                      <v-col cols="12" md="7">
                        <div
                          v-if="form.advance_payment"
                          class="d-flex flex-column"
                        >
                          <v-card elevation="0" class="py-2 mb-4">
                            <yes-no-component
                              v-model="form.advance_payment_paid"
                              label="Avans Paid"
                              @input="form.advance_payment_paid = $event"
                            />
                          </v-card>
                          <v-row>
                            <v-col cols="12" sm="4" class="pr-sm-1 py-0">
                              <v-text-field
                                v-model="percentage"
                                :error-messages="firstError('form.advance_payment_percentage')"
                                label="Avans Percentage"
                                dense
                                append-icon="%"
                                outlined
                              />
                            </v-col>
                            <v-col cols="12" sm="4" class="px-sm-2 py-0">
                              <v-text-field
                                v-model="form.advance_payment_amount"
                                :error-messages="firstError('form.advance_payment_amount')"
                                label="Iznos za uplatu"
                                dense
                                append-icon="€"
                                outlined
                                @input="advancePaymentAmountChanged"
                              />
                            </v-col>
                            <v-col cols="12" sm="4" class="pl-sm-1 py-0">
                              <v-text-field
                                v-model="remaningBalance"
                                :error-messages="firstError('form.advance_payment_percentage')"
                                label="Ostatak"
                                readonly
                                dense
                                append-icon="€"
                                outlined
                              />
                            </v-col>
                          </v-row>
                          <v-row class="mt-4">
                            <v-col cols="12" sm="4" class="pr-sm-1 py-0">
                              <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="form.advance_payment_due_date"
                                    label="Avans Due Date"
                                    :error-messages="firstError('form.advance_payment_due_date')"
                                    append-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                  />
                                </template>
                                <v-date-picker
                                  v-model="form.advance_payment_due_date"
                                  :min="new Date().toISOString()"
                                  @input="updateDateTime"
                                  no-title
                                />
                              </v-menu>
                            </v-col>
                            <v-col cols="12" sm="4" class="py-0 px-sm-2">
                              <v-select
                                v-model="form.advance_payment_due_time"
                                :items="['11:00', '17:00']"
                                :error-messages="firstError('form.advance_payment_due_time')"
                                outlined
                                placeholder="Select Time"
                                dense
                              />
                            </v-col>
                            <v-col cols="12" sm="4" class="pl-sm-1 py-0">
                              <v-select
                                v-model="form.user_payout_version_id"
                                :items="payouts"
                                :error-messages="firstError('form.user_payout_version_id')"
                                item-value="user_payout_version_id"
                                item-text="type"
                                outlined
                                placeholder="Select Payment Method"
                                dense
                              >
                              </v-select>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                      <v-col cols="12" md="5" class="pl-0 pt-0">
                        <v-card elevation="0" class="pa-3 py-2 mt-1 mt-md-0" v-if="proof1 || proof2">
                          <div class="d-flex align-center justify-center mt-2">
                            <div class="d-flex flex-column align-center mr-4">
                              <media-library-attachment
                                v-if="proof1"
                                :key="Object.keys(proof1)[0]"
                                class="attachment-government attachment-government-small attachment-government-front"
                                name="attachment"
                                @change="proof1Attachment = $event"
                                :after-upload="submitProof1"
                                :initial-value="proof1"
                                :validation-errors="attachmentErrors"
                                :validation-rules="{
                                accept: ['image/png', 'image/jpeg'],
                                maxSizeInKB: 1024*10,
                                minSizeInKB: 10
                                }"
                                :upload-domain="uploadDomain"
                                :translations="{ ...translations, selectOrDragMax: $t('Upload payment proof') }"
                              />
                              <v-btn
                                v-if="proof1 && Object.keys(proof1)[0] && proof1[Object.keys(proof1)[0]] && proof1[Object.keys(proof1)[0]].original_url"
                                :href="proof1[Object.keys(proof1)[0]].original_url" target="_blank"
                                dense
                                text
                              >
                                Open
                              </v-btn>
                            </div>

                            <div class="d-flex flex-column align-center">
                              <media-library-attachment
                                v-if="proof2"
                                :key="Object.keys(proof2)[0]"
                                class="attachment-government attachment-government-small attachment-government-front"
                                name="attachment"
                                @change="proof2Attachment = $event"
                                :after-upload="submitProof2"
                                :initial-value="proof2"
                                :validation-errors="attachmentErrors"
                                :validation-rules="{
                                accept: ['image/png', 'image/jpeg'],
                                maxSizeInKB: 1024*10,
                                minSizeInKB: 10
                                }"
                                :upload-domain="uploadDomain"
                                :translations="{ ...translations, selectOrDragMax: $t('Upload payment proof') }"
                              />
                              <v-btn
                                v-if="proof2 && Object.keys(proof2)[0] && proof2[Object.keys(proof2)[0]] && proof2[Object.keys(proof2)[0]].original_url"
                                :href="proof2[Object.keys(proof2)[0]].original_url" target="_blank"
                                dense
                                text
                              >
                                Open
                              </v-btn>
                            </div>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-card outlined class="px-3 py-3">
                    <v-textarea
                        v-model="form.comment"
                        :error-messages="firstError('form.comment')"
                        placeholder="Add a comment here"
                        label="Comment"
                        outlined
                        filled
                        counter
                        auto-grow
                        rows="1"
                    ></v-textarea>
                  </v-card>
                </v-col>
              </v-row>
          </v-col>
        </v-row>
    </div>

</template>
<script>
import moment from 'moment';
import axios from 'axios';
import { mapGetters } from 'vuex';
import YesNoComponent from '@/components/YesNoComponent.vue';
import { validationMixin } from 'vuelidate';
import {
  required,
  requiredIf,
  minValue,
  maxValue,
  maxLength,
} from 'vuelidate/lib/validators';
import _ from 'lodash';
import { DATE_FORMAT } from '@/components/calendar/config';
import MediaLibraryMixin from '@/lib/MediaLibraryMixin';
import FormValidationMixin from '../../lib/FormValidationMixin';

export default {
  mixins: [validationMixin, FormValidationMixin, MediaLibraryMixin],
  props: {
    id: {
      required: true,
      type: String,
    },
    reservation: {
      required: false,
      type: Object,
    },
    makeReservationFromInquiry: {
      type: Object,
      required: false,
    },
  },
  components: {
    YesNoComponent,
  },
  validations() {
    return {
      form: {
        paid: {
          required,
        },
        advance_payment: {
          required,
        },
        advance_payment_due_date: {
          required: requiredIf(function () {
            return this.isAdvancePaymentSelected;
          }),
        },
        advance_payment_due_time: {
          required: requiredIf(function () {
            return this.isAdvancePaymentSelected;
          }),
        },
        user_payout_version_id: {
          required: requiredIf(function () {
            return this.isAdvancePaymentSelected;
          }),
        },
        advance_payment_amount: {
          required: requiredIf(function () {
            return this.isAdvancePaymentSelected;
          }),
          minValue: minValue(this.isAdvancePaymentSelected ? 5 : 0),
          maxValue: maxValue(this.total),
        },
        advance_payment_percentage: {
          minValue: minValue(this.isAdvancePaymentSelected ? 5 : 0),
          maxValue: maxValue(100),
          required: requiredIf(function () {
            return this.isAdvancePaymentSelected;
          }),
        },
        custom_price: {
          total: {
            required: requiredIf(function () {
              return this.form.custom_pricing;
            }),
            minValue: minValue(this.form.custom_pricing ? 3 : null),
            maxValue: maxValue(this.form.custom_pricing ? 999999 : null),
          },
        },
        comment: {
          maxLength: maxLength(255),
        },
      },
    };
  },
  data() {
    return {
      menu: false,
      showCustomPriceRemoveConfirm: false,
      customPricingAlreadyActive: false,
      form: {
        paid: false,
        advance_payment: false,
        advance_payment_paid: false,

        advance_payment_amount: undefined,
        advance_payment_due_date: undefined,
        advance_payment_due_time: undefined,
        user_payout_version_id: '',

        advance_payment_percentage: undefined,

        custom_pricing: false,
        custom_price: {
          total: undefined,
        },
        comment: undefined,
      },
      payouts: [],
      skipWatcher: false,

      proof1: undefined,
      proof1Attachment: undefined,
      proof2: undefined,
      proof2Attachment: undefined,
      attachmentErrors: {},
    };
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('update', this.form);
      },
    },
    calculator: {
      deep: true,
      handler() {
        this.calculateAdvancePaymentParams();
      },
    },
  },
  mounted() {
    if (this.reservation) { // only for edit
      this.form.paid = this.reservation.paid;
      this.form.advance_payment = this.reservation.advance_payment;

      if (this.reservation.custom_pricing) {
        this.form.custom_pricing = Boolean(this.reservation.custom_pricing);
        this.form.custom_price.total = this.reservation.total;
        this.form.custom_price.ownerTotal = this.reservation.owner_total;
        this.form.custom_price.serviceFee = this.reservation.service_fee;
        this.form.custom_price.taxOnServiceFee = this.reservation.tax;
        this.customPricingAlreadyActive = true;
      }

      if (this.reservation.advance_payment) {
        this.form.advance_payment_paid = this.reservation.advance_payment_paid;
        this.form.advance_payment_percentage = this.calculatePercentage(this.total, this.reservation.advance_payment_amount);

        this.form.advance_payment_amount = this.reservation.advance_payment_amount;
        this.form.user_payout_version_id = this.reservation.user_payout_version_id;

        if (this.reservation.advance_payment_due_date) {
          const datetime = moment(this.reservation.advance_payment_due_date);
          this.form.advance_payment_due_date = datetime.clone().format(DATE_FORMAT);
          this.form.advance_payment_due_time = datetime.clone().format('HH:mm');
        }
        if (this.reservation.advance_payment_proofs) {
          if (this.reservation.advance_payment_proofs.proof1) {
            this.proof1 = this.reservation.advance_payment_proofs.proof1;
          }
          if (this.reservation.advance_payment_proofs.proof2) {
            this.proof2 = this.reservation.advance_payment_proofs.proof2;
          }
        }
      }

      if (this.reservation.comment) {
        this.form.comment = this.reservation.comment;
      }
    }

    // check if AVANS is needed for this reservation from inquiry.
    if (this.makeReservationFromInquiry
      && this.makeReservationFromInquiry.calculator
      && this.makeReservationFromInquiry.calculator.advance_payment
      && this.makeReservationFromInquiry.calculator.advance_payment.required
      && this.makeReservationFromInquiry.calculator.advance_payment.amount
      && this.makeReservationFromInquiry.calculator.advance_payment.percentage
      && this.makeReservationFromInquiry.calculator.advance_payment.due_date
      && this.makeReservationFromInquiry.user_payout_version_id
    ) {
      this.form.user_payout_version_id = this.makeReservationFromInquiry.user_payout_version_id;
      this.form.advance_payment = this.makeReservationFromInquiry.calculator.advance_payment.required;
      this.form.advance_payment_amount = this.makeReservationFromInquiry.calculator.advance_payment.amount;
      this.form.advance_payment_percentage = this.makeReservationFromInquiry.calculator.advance_payment.percentage;
      const datetime = moment(this.makeReservationFromInquiry.calculator.advance_payment.due_date);
      this.form.advance_payment_due_date = datetime.clone().format(DATE_FORMAT);
      this.form.advance_payment_due_time = datetime.clone().format('HH:mm');
    }

    this.$emit('update', this.form);
    // console.log('Mounted RF');
    this.loadPaymentMethods();
  },
  computed: {
    ...mapGetters(['calculator']),
    isAdvancePaymentSelected() {
      return Boolean(this.form.advance_payment);
    },
    total() {
      if (this.form.custom_pricing && this.form.custom_price.total) {
        return this.form.custom_price.total;
      }
      return (this.calculator && this.calculator.total)
        ? this.calculator.total
        : this.reservation.calculator.total;
    },
    remaningBalance() {
      if (this.form.advance_payment_amount) {
        return (this.total - this.form.advance_payment_amount).toFixed(2);
      }
      return undefined;
    },
    percentage: {
      get() {
        return this.form.advance_payment_percentage
          ? this.form.advance_payment_percentage
          : undefined;
      },
      set(value) {
        this.form.advance_payment_percentage = value;
        this.form.advance_payment_amount = (this.total * (this.form.advance_payment_percentage / 100)).toFixed(2);
      },
    },
  },
  methods: {
    removeCustomPrice() {
      this.form.custom_pricing = false;
      this.showCustomPriceRemoveConfirm = false;
      this.customPricingAlreadyActive = false;
      this.resetCustomPrice();
      this.calculateAdvancePaymentParams();
    },
    closeCustomPriceRemoveConfirmModal() {
      this.showCustomPriceRemoveConfirm = false;
      this.form.custom_pricing = true;
      this.customPricingAlreadyActive = true;
    },
    calculateAdvancePaymentParams() {
      // console.log('calculateAdvancePaymentParams');
      // ako je vec placen avans, onda samo povecaj remaning + sracunaj procenat
      if (this.form.advance_payment_paid) {
        // console.log('AVANS JE VEC PLACEN: sračunaj samo PROCENAT + REMANING');
        this.form.advance_payment_percentage = this.calculatePercentage(this.total, this.form.advance_payment_amount);
      } else {
        // ako nije placen avans onda sracunaj amount
        // console.log('AVANS -NIJE- PLACEN: sracunaj: amount + remaning');
        // eslint-disable-next-line no-lonely-if
        if (this.form.advance_payment_percentage) {
          this.form.advance_payment_amount = (this.total * (this.form.advance_payment_percentage / 100)).toFixed(2);
        }
      }
    },
    calculatePercentage(total, value) {
      return ((value / total) * 100).toFixed(2);
    },
    loadPaymentMethods() {
      this.$store.dispatch('loading', true);
      axios
        .get(`owner/${this.id}/payout-method`)
        .then(({ data }) => {
          if (data) {
            let payouts = data;

            if (this.reservation && this.reservation.payout_version) {
              // if payout version is not in payouts, add it
              if (!payouts.find((payout) => payout.user_payout_version_id === this.reservation.payout_version.id)) {
                // map payouts so type is {payout.type version}
                payouts = payouts.map((payout) => {
                  let { type } = payout;
                  if (payout.type === this.reservation.payout_version.payout.type) {
                    type = `${payout.type} V${payout.version}`;
                  }
                  return {
                    ...payout,
                    type,
                  };
                });

                payouts.push({
                  ...this.reservation.payout_version,
                  type: `${this.reservation.payout_version.payout.type} V${this.reservation.payout_version.version} (used in reservation)`,
                  user_payout_version_id: this.reservation.payout_version.id,
                });
              }
            }

            this.payouts = payouts;
          }
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    updateDateTime() {
      this.menu = false;
    },
    validateForm() {
      this.$v.$touch();
    },
    advancePaymentAmountChanged() {
      this.form.advance_payment_percentage = ((this.form.advance_payment_amount / this.total) * 100).toFixed(2);
    },
    submitProof1() {
      this.uploadAttachment('proof1', this.proof1Attachment);
    },
    submitProof2() {
      this.uploadAttachment('proof2', this.proof2Attachment);
    },
    uploadAttachment(collection, attachment) {
      this.$store.dispatch('loading', true);
      axios
        .post(`/calendar/reservations/${this.reservation.id}/media/attachment/${collection}`, {
          media: attachment,
        })
        .then(() => {
          this.$store.dispatch('loading', false);
          this.$store.dispatch('message', this.$t('Attachment photo changed'));
        })
        .catch((error) => {
          this.$store.dispatch('loading', false);
          this.$store.dispatch('setErrors', error.response.data.errors);
        });
    },
    debounceCustomPriceTotalChanged: _.debounce(function () {
      this.customPriceTotalChanged();
    }, 500),
    customPriceTotalChanged() {
      if (!this.form.custom_price.total) {
        this.calculateAdvancePaymentParams();
        return;
      }
      this.$store.dispatch('loading', true);
      const params = {
        total: this.form.custom_price.total,
      };
      axios
        .get(`calendar/${this.id}/custom-price`, {
          params,
        })
        .then(({ data }) => {
          if (data) {
            this.form.custom_price = data;
            this.calculateAdvancePaymentParams();
          }
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    resetCustomPrice() {
      this.form.custom_price = {
        total: undefined,
      };
    },
    customPricingChanged(val) {
      if (!val) {
        if (this.customPricingAlreadyActive) {
          this.form.custom_pricing = true;
          this.showCustomPriceRemoveConfirm = true;
        }
        this.customPricingAlreadyActive = false;
      } else {
        this.customPricingAlreadyActive = true;
        // eslint-disable-next-line
        if (this.reservation && this.reservation.calculator.total) {
          this.form.custom_price = {
            total: this.reservation.calculator.total,
            ownerTotal: this.reservation.calculator.ownerTotal,
            serviceFee: this.reservation.calculator.serviceFee,
            taxOnServiceFee: this.reservation.calculator.taxOnServiceFee,
          };
        } else if (this.calculator && this.calculator.total && this.calculator.ownerTotal && this.calculator.serviceFee && this.calculator.taxOnServiceFee) {
          this.form.custom_price = {
            total: this.calculator.total,
            ownerTotal: this.calculator.ownerTotal,
            serviceFee: this.calculator.serviceFee,
            taxOnServiceFee: this.calculator.taxOnServiceFee,
          };
        }
      }
    },
  },
};
</script>
